import React from "react"
import styled from "styled-components"
import { Spring } from "react-spring/renderprops"

const Wrapper = styled.div`
  padding: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  opacity: ${props => props.opacity};
`

const HtwoTitle = styled.h2`
  color: #999999;
  padding: 30px;
`

const ParText = styled.p`
  color: #999999;
`

const Hoteis = props => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ duration: 1000 }}
    >
      {props => (
        <Wrapper opacity={props.opacity}>
          <HtwoTitle>Hoteis</HtwoTitle>
          <ParText>
            Ibis budget Belo Horizonte Afonso Pena{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.accorhotels.com/pt-br/hotel-8527-ibis-budget-belo-horizonte-afonso-pena/index.shtml"
            >
              clique aqui
            </a>
          </ParText>
          <ParText>
            Hotel ibis Belo Horizonte Savassi{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.accorhotels.com/pt-br/hotel-7021-ibis-belo-horizonte-savassi/index.shtml"
            >
              clique aqui
            </a>
          </ParText>
          <ParText>
            Royal Savassi{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://www.royalhoteis.com.br/royalsavassiboutique/"
            >
              clique aqui
            </a>
          </ParText>

          <HtwoTitle>Pampulha</HtwoTitle>
          <ParText>
            CHA Pampulha Design Hotel{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.booking.com/hotel/br/best-western-plus-pampulha-design.pt-br.html?aid=1702940;label=belo-horizonte;sid=63a5c08ec4ec7b33572eedc94ed9aced;atlas_src=sr_iw_btn;dest_id=12063;dest_type=district;dist=0;room1=A%2CA;sb_price_type=total;type=total;ucfs=1&"
            >
              clique aqui
            </a>
          </ParText>
          <ParText>
            Pousada Lagoa{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.booking.com/hotel/br/pousada-lagoa.pt-br.html?aid=1702940;label=belo-horizonte;sid=63a5c08ec4ec7b33572eedc94ed9aced;atlas_src=sr_iw_btn;dest_id=12063;dest_type=district;dist=0;room1=A%2CA;sb_price_type=total;type=total;ucfs=1&"
            >
              clique aqui
            </a>
          </ParText>
          <ParText>
            Quality Hotel Pampulha - Localizado na Av.Pres,Antônio Carlos, a
            3,7km(8min) do local da festa{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://qualityhotelpampulha.com-brasil.website/pt/"
            >
              clique aqui
            </a>
          </ParText>
        </Wrapper>
      )}
    </Spring>
  )
}

export default Hoteis
