import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'


const Wrapper = styled.div`
padding: 15px;
padding-top: 50px;
padding-bottom: 50px;
opacity: ${props => props.opacity};
`

const HtwoTitle = styled.h2`
color: #999999;
padding: 30px;
`

const ParText = styled.p`
color: #999999;
`

const LocalDaFesta = (props) => {
    const desize = props.desize
    return (
        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
        >
            {props => (
                <Wrapper opacity={ props.opacity }>
                    <HtwoTitle>Local da Festa</HtwoTitle>
                    <section style={{ top: '' + (100 * desize) + 'px' }}>
                        <ParText>A Celebração ocorrerá no dia 23/10/2021 às 17h no Colonial Panpulha Recepções</ParText>
                        <ParText>Endereço: Av. Otacílio Negrão de Lima, 16200 - Pampulha, Belo Horizonte - MG (
                    <a rel="noopener noreferrer" target='_blank' href='https://goo.gl/maps/gGw23QPhpZERUWiX6'>clique aqui</a>)
                    </ParText>
                    </section>
                </Wrapper>
            )}
        </Spring>
    )
}

export default LocalDaFesta


