import React from 'react'


const Claric = (props) => 
<svg
   width="72.832764mm"
   height="10.340454mm"
   viewBox="0 0 72.832764 10.340454"
   version="1.1"
   id="svg8"
   opacity={props.ops}
>
  <defs
     id="defs2" />
  <g
     id="layer1"
     transform="translate(-72.704157,-119.1463)">
    <g
       aria-label="Clarice &amp; João"
       style={{
        fontStyle:'normal',
        fontWeight:'normal',
        fontSize:'10.58333302px',
        lineHeight:'1.25',
        fontFamily:'sans-serif',
        letterSpacing:'0px',
        wordSpacing:'0px',
        fill:'#9e9e9e',
        fillOpacity:'1',
        stroke:'none',
        strokeWidth:'0.26458332"',
       }}
       
       id="text817">
      <path
         d="m 78.926,120.24701 v 1.1007 q -0.5271,-0.49092 -1.126546,-0.7338 -0.594279,-0.24288 -1.266073,-0.24288 -1.322917,0 -2.025716,0.81132 -0.7028,0.80615 -0.7028,2.33578 0,1.52445 0.7028,2.33577 0.702799,0.80615 2.025716,0.80615 0.671794,0 1.266073,-0.24288 0.599446,-0.24288 1.126546,-0.7338 v 1.09037 q -0.54777,0.37207 -1.16272,0.55811 -0.609782,0.18603 -1.291911,0.18603 -1.751831,0 -2.759521,-1.0697 -1.007691,-1.07487 -1.007691,-2.93005 0,-1.86036 1.007691,-2.93006 1.00769,-1.07487 2.759521,-1.07487 0.692464,0 1.302246,0.18604 0.61495,0.18086 1.152385,0.54777 z"
         style={{strokeWidth:'0.26458332'}}
         id="path822" />
      <path
         d="m 80.507299,119.32717 h 0.950846 v 8.04085 h -0.950846 z"
         style={{strokeWidth:'0.26458332'}}
         id="path824" />
      <path
         d="m 86.07285,124.45864 q -1.152384,0 -1.596802,0.26355 -0.444417,0.26355 -0.444417,0.89917 0,0.50642 0.330729,0.80615 0.335897,0.29455 0.909505,0.29455 0.79065,0 1.266073,-0.5581 0.480591,-0.56327 0.480591,-1.49345 v -0.21187 z m 1.896525,-0.39274 v 3.30212 h -0.950846 v -0.8785 q -0.325562,0.5271 -0.81132,0.78031 -0.485759,0.24805 -1.188558,0.24805 -0.888835,0 -1.415934,-0.49609 -0.521932,-0.50126 -0.521932,-1.33842 0,-0.97669 0.651123,-1.47278 0.65629,-0.49609 1.953369,-0.49609 h 1.333252 v -0.093 q 0,-0.65629 -0.434082,-1.01286 -0.428915,-0.36174 -1.209229,-0.36174 -0.496093,0 -0.966349,0.11886 -0.470255,0.11886 -0.904337,0.35657 v -0.8785 q 0.521931,-0.20154 1.012858,-0.29972 0.490926,-0.10336 0.956014,-0.10336 1.255737,0 1.875854,0.65113 0.620117,0.65112 0.620117,1.97404 z"
         style={{strokeWidth:'0.26458332'}}
         id="path826" />
      <path
         d="m 93.28688,122.46909 q -0.160197,-0.093 -0.3514,-0.13436 -0.186035,-0.0465 -0.413411,-0.0465 -0.806153,0 -1.240235,0.5271 -0.428914,0.52193 -0.428914,1.50378 v 3.04891 h -0.956014 v -5.78776 h 0.956014 v 0.89917 q 0.299723,-0.5271 0.780314,-0.78032 0.480591,-0.25838 1.167887,-0.25838 0.09819,0 0.217041,0.0155 0.118856,0.0103 0.26355,0.0362 z"
         style={{strokeWidth:'0.26458332'}}
         id="path828" />
      <path
         d="m 94.29457,121.58026 h 0.950846 v 5.78776 H 94.29457 Z m 0,-2.25309 h 0.950846 v 1.20406 H 94.29457 Z"
         style={{strokeWidth:'0.26458332'}}
         id="path830" />
      <path
         d="m 101.39491,121.80247 v 0.88883 q -0.40307,-0.22221 -0.81132,-0.33073 -0.40307,-0.11369 -0.816485,-0.11369 -0.925008,0 -1.436605,0.58912 -0.511596,0.58394 -0.511596,1.64331 0,1.05936 0.511596,1.64847 0.511597,0.58395 1.436605,0.58395 0.413415,0 0.816485,-0.10852 0.40825,-0.11369 0.81132,-0.3359 v 0.8785 q -0.39791,0.18604 -0.82682,0.27905 -0.42375,0.093 -0.904338,0.093 -1.307414,0 -2.077392,-0.82165 -0.769979,-0.82166 -0.769979,-2.21692 0,-1.41594 0.775146,-2.22726 0.780314,-0.81132 2.134237,-0.81132 0.439246,0 0.857826,0.093 0.41858,0.0879 0.81132,0.26872 z"
         style={{strokeWidth:'0.26458332'}}
         id="path832" />
      <path
         d="m 108.0095,124.23643 v 0.46509 h -4.37183 q 0.062,0.98185 0.58911,1.49861 0.53227,0.5116 1.47795,0.5116 0.54777,0 1.05936,-0.13436 0.51677,-0.13436 1.0232,-0.40308 v 0.89917 q -0.5116,0.21704 -1.04903,0.33073 -0.53744,0.11369 -1.09038,0.11369 -1.38493,0 -2.19625,-0.80615 -0.80615,-0.80615 -0.80615,-2.18075 0,-1.4211 0.76481,-2.25309 0.76998,-0.83716 2.07223,-0.83716 1.16789,0 1.84485,0.75448 0.68213,0.74931 0.68213,2.04122 z m -0.95085,-0.27906 q -0.0103,-0.78031 -0.43925,-1.2454 -0.42375,-0.46509 -1.12655,-0.46509 -0.79581,0 -1.2764,0.44959 -0.47543,0.44958 -0.54777,1.26607 z"
         style={{strokeWidth:'0.26458332'}}
         id="path834" />
      <path
         d="m 114.51556,123.2184 q -0.47026,0.41858 -0.69247,0.83716 -0.21704,0.41341 -0.21704,0.86816 0,0.75448 0.54777,1.25574 0.54777,0.50126 1.37459,0.50126 0.49093,0 0.91984,-0.16019 0.42892,-0.16537 0.80616,-0.4961 z m 0.72863,-0.57877 2.62517,2.68717 q 0.30489,-0.45992 0.47542,-0.98185 0.17053,-0.5271 0.20154,-1.11621 h 0.96118 q -0.062,0.68213 -0.33073,1.34875 -0.26872,0.66663 -0.74931,1.31775 l 1.44177,1.47278 h -1.30224 l -0.73897,-0.75964 q -0.53744,0.45992 -1.12655,0.68729 -0.58911,0.22221 -1.26607,0.22221 -1.24541,0 -2.03605,-0.70797 -0.79065,-0.71313 -0.79065,-1.82417 0,-0.66146 0.34623,-1.24024 0.34623,-0.58394 1.03869,-1.09554 -0.24804,-0.32556 -0.37723,-0.64595 -0.12919,-0.32557 -0.12919,-0.63562 0,-0.83716 0.5736,-1.34359 0.57361,-0.5116 1.52446,-0.5116 0.42891,0 0.85266,0.093 0.42891,0.093 0.86816,0.27905 v 0.94051 q -0.44958,-0.24288 -0.85783,-0.3669 -0.40824,-0.12919 -0.75964,-0.12919 -0.5426,0 -0.88367,0.28939 -0.33589,0.28422 -0.33589,0.73897 0,0.26355 0.14986,0.53227 0.15503,0.26355 0.62528,0.74931 z"
         style={{strokeWidth:'0.26458332'}}
         id="path836" />
      <path
         d="m 124.59763,119.65273 h 1.04386 v 7.17785 q 0,1.39527 -0.53226,2.02572 -0.5271,0.63045 -1.70016,0.63045 h -0.39791 v -0.8785 h 0.32556 q 0.69247,0 0.97669,-0.38757 0.28422,-0.38757 0.28422,-1.3901 z"
         style={{strokeWidth:'0.26458332'}}
         id="path838" />
      <path
         d="m 129.9203,122.24688 q -0.76481,0 -1.20923,0.59945 -0.44441,0.59428 -0.44441,1.63298 0,1.03869 0.43925,1.63814 0.44441,0.59428 1.21439,0.59428 0.75965,0 1.20406,-0.59945 0.44442,-0.59944 0.44442,-1.63297 0,-1.02836 -0.44442,-1.62781 -0.44441,-0.60462 -1.20406,-0.60462 z m 0,-0.80615 q 1.24024,0 1.9482,0.80615 0.70797,0.80616 0.70797,2.23243 0,1.4211 -0.70797,2.23242 -0.70796,0.80615 -1.9482,0.80615 -1.2454,0 -1.95337,-0.80615 -0.7028,-0.81132 -0.7028,-2.23242 0,-1.42627 0.7028,-2.23243 0.70797,-0.80615 1.95337,-0.80615 z"
         style={{strokeWidth:'0.26458332'}}
         id="path840" />
      <path
         d="m 136.77776,124.45864 q -1.15238,0 -1.5968,0.26355 -0.44442,0.26355 -0.44442,0.89917 0,0.50642 0.33073,0.80615 0.3359,0.29455 0.90951,0.29455 0.79065,0 1.26607,-0.5581 0.48059,-0.56327 0.48059,-1.49345 v -0.21187 z m 1.89653,-0.39274 v 3.30212 h -0.95085 v -0.8785 q -0.32556,0.5271 -0.81132,0.78031 -0.48576,0.24805 -1.18856,0.24805 -0.88883,0 -1.41593,-0.49609 -0.52193,-0.50126 -0.52193,-1.33842 0,-0.97669 0.65112,-1.47278 0.65629,-0.49609 1.95337,-0.49609 h 1.33325 v -0.093 q 0,-0.65629 -0.43408,-1.01286 -0.42891,-0.36174 -1.20923,-0.36174 -0.49609,0 -0.96635,0.11886 -0.47025,0.11886 -0.90434,0.35657 v -0.8785 q 0.52194,-0.20154 1.01286,-0.29972 0.49093,-0.10336 0.95602,-0.10336 1.25573,0 1.87585,0.65113 0.62012,0.65112 0.62012,1.97404 z m -2.4753,-3.77755 -0.29456,-0.28422 q -0.11369,-0.10336 -0.20154,-0.14986 -0.0827,-0.0517 -0.14986,-0.0517 -0.19637,0 -0.28939,0.1912 -0.093,0.18604 -0.10335,0.60978 h -0.64595 q 0.0103,-0.69763 0.27388,-1.07487 0.26355,-0.3824 0.73381,-0.3824 0.19637,0 0.36173,0.0724 0.16537,0.0723 0.35657,0.24288 l 0.29455,0.28422 q 0.11369,0.10335 0.19637,0.15502 0.0879,0.0465 0.15503,0.0465 0.19637,0 0.28939,-0.18603 0.093,-0.1912 0.10335,-0.61495 h 0.64596 q -0.0103,0.69763 -0.27389,1.08004 -0.26354,0.37723 -0.7338,0.37723 -0.19637,0 -0.36174,-0.0723 -0.16536,-0.0724 -0.35656,-0.24288 z"
         style={{strokeWidth:'0.26458332'}}
         id="path842" />
      <path
         d="m 142.88075,122.24688 q -0.76481,0 -1.20923,0.59945 -0.44441,0.59428 -0.44441,1.63298 0,1.03869 0.43925,1.63814 0.44441,0.59428 1.21439,0.59428 0.75965,0 1.20406,-0.59945 0.44442,-0.59944 0.44442,-1.63297 0,-1.02836 -0.44442,-1.62781 -0.44441,-0.60462 -1.20406,-0.60462 z m 0,-0.80615 q 1.24024,0 1.9482,0.80615 0.70797,0.80616 0.70797,2.23243 0,1.4211 -0.70797,2.23242 -0.70796,0.80615 -1.9482,0.80615 -1.2454,0 -1.95337,-0.80615 -0.7028,-0.81132 -0.7028,-2.23242 0,-1.42627 0.7028,-2.23243 0.70797,-0.80615 1.95337,-0.80615 z"
         style={{strokeWidth:'0.26458332'}}
         id="path844" />
    </g>
  </g>
</svg>


export default Claric