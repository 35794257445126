import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'


const Wrapper = styled.div`
padding: 15px;
padding-top: 50px;
padding-bottom: 50px;
opacity: ${props => props.opacity};
`

const HtwoTitle = styled.h2`
color: #999999;
padding: 30px;
`

const ParText = styled.p`
color: #999999;
`

const Hoteis = (props) => {
    return (
        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
        >
            {props => (
                <Wrapper opacity={props.opacity}>
                    <HtwoTitle>Listas de Presentes</HtwoTitle>
                    <ParText>La Ville - <a rel="noopener noreferrer" target='_blank' href='https://www.lavillecasa.com.br/list/B105724?PS=12&O=OrderByNameASC'>clique aqui</a></ParText>
                    <ParText>Camicado - <a rel="noopener noreferrer" target='_blank' href='https://camicado.com.br/lista/convidado/clariceejoao'>clique aqui</a></ParText>
                    <ParText>Lista Virtual - <a rel="noopener noreferrer" target='_blank' href='https://sites.icasei.com.br/joaoeclarice/pt_br/store/9/1/1'>clique aqui</a>
                    <p>(A ideia inicial era viajar de lua-de-mel para a África do Sul, mas esse plano está adiado momentaneamente, então iremos curtir a lua-de-mel no Sul do Brasil.)</p></ParText>
                    <ParText></ParText>
                </Wrapper>
            )}
        </Spring>
    )
}

export default Hoteis


