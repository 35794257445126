import React, { Component } from "react"
import "./App.css"
import { Spring } from "react-spring/renderprops"
import styled from "styled-components"

import Claric from "./Claric"
import Pics from "./Pics"
import Tutuca from "./Tutuca"
import SalaoDeBeleza from "./SalaoDeBeleza"
import Hoteis from "./Hoteis"
import Presentes from "./Presentes"
import RSVP from "./RSVP"
import LocalDaFesta from "./LocalDaFesta"
import Dicas from "./Dicas"

const WrapperLogo = styled.div`
  max-width: ${props =>
    props.innerWidth > 1080 && props.innerWidth < 1080 * 1.1
      ? props.innerWidth
      : 1080}px;
  margin: auto;
  overflow: ${props => (props.innerWidth > 1080 * 1.1 ? "unset" : "hidden")};
  min-height: 60vh;
`

const WrapperMenu = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding-top: 20px;
`
const WrapperMenuItem = styled.div`
  width: 14%;
  height: 18px;
  margin-top: 10px;
  padding: 10px;
  display: inline-block;
  flex-wrap: wrap;
  border-right: 1px solid #9e9e9e;
  vertical-align: top;
  color: #9e9e9e;
  cursor: pointer;

  @media (max-width: 740px) {
    width: 25%;
  }
`

const DateSpan = styled.span`
font-size: 24px;
color: #9e9e9e;
opacity: ${props => props.opacity};
font-fanily: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`

const iconStyle2 = { color: '#999999', cursor: 'pointer', verticalAlign: 'bottom' }

const divDropDown = {
  zIndex: "20",
  position: "absolute !important",
  border: "#999999 solid 1px",
  backgroundColor: "white",
  width: "150px",
  marginLeft: "-120px",
}

const menu = [
  "Home",
  "Dicas",
  "Hoteis",
  "Local da festa",
  "RSVP",
  "Lista de Presentes",
  "Salões de Beleza",
]

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listOpen: false,
      page: "home",
    }
  }

  handleClickOutside() {
    this.setState({
      listOpen: false,
    })
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen,
    }))
  }

  changePage(e) {
    this.setState({ page: e })
  }

  render() {
    var desize = 0.8
    const innerWidth = window.innerWidth,
      innerHeight = window.innerHeight

    var picturesScale, tacoWidth, marginLeftTaco, bottomTaco

    tacoWidth = 7000
    const minpicScale = 0.7
    picturesScale =
      (minpicScale * window.innerHeight) / 1008 > minpicScale
        ? minpicScale
        : (minpicScale * window.innerHeight) / 1008
    marginLeftTaco = (-tacoWidth * (1 - picturesScale)) / 2
    bottomTaco = (-350 * (1 - picturesScale)) / 2

    const accelerate = 0.8
    const durationChrist = 4000 * accelerate,
      delayChrist = 5000 * accelerate,
      durationLake = 7000 * accelerate,
      delayLake = 7000 * accelerate,
      durationPamp = 12000 * accelerate,
      durationTitle = 5000 * accelerate,
      delayTitle = 10000 * accelerate,
      durationMenu = 4000 * accelerate,
      delayMenu = 11000 * accelerate,
      durationPhotos = 5000 * accelerate,
      delayPhotos = 13000 * accelerate

    const topLogo =
      innerWidth < 800 &&
      (innerHeight * 0.6 - (innerWidth * 1.1 * 140) / 240) / 2 > 0
        ? (innerHeight * 0.6 - (innerWidth * 1.1 * 140) / 240) / 2
        : 0

    return (
      <div className="App">
        <div
          style={{ minHeight: "600px", paddingTop: "20px", maxWidth: "100%" }}
        >
          {
            // Logo
          }

          <WrapperLogo innerWidth={innerWidth} innerHeight={innerHeight}>
            <div style={{ top: topLogo }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="svg8"
                version="1.1"
                viewBox="-15 0 240.81133 140"
                //height="137.90889mm"
                //width="210.81133mm"

                height="100%"
                width="110%"
                style={{ transform: 'translateX(-6%)', }}
              >


                <g
                  transform="translate(0.35627755,-24.065739)"
                  id="layer3">
                  <Spring
                    from={{ x: 1000 }}
                    to={{ x: 700 }}
                    config={{ duration: durationChrist, delay: delayChrist }}
                  >
                    {props => (
                      <g
                        transform="matrix(0.71426933,0,0,0.71426933,102.98994,6.2698344)"
                        id="g827"
                        style={{
                          //Christ
                          fill: '#ffffff',
                          fillOpacity: '1',
                          strokeDasharray: '1000',
                          strokeDashoffset: props.x,
                        }}
                      >

                        <g id="layer2" >
                          <path d="M23.295 102.931c.075-.341 4.809-20.37 5.6-22.062.404-2.674 1.909-7.386 3.135-10.304.192-1.84 1.433-8.677 2.688-12.879.7-1.84 1.918-4.686 2.688-6.831.312-.43 1.004-.529 1.232-1.008-.313-1.338.035-2.461 0-3.696.234-.773 1.11-1.243 1.343-2.016.12-.393-.128-.842 0-1.232.66-1.16 1.941-2.487 2.8-3.808.493-.757 1.344-2.352 1.344-2.352.82-.695 3.024-1.12 3.024-1.12.308-.343.09-1.088.392-1.511.767-.907 3.045-2.29 3.423-2.52.174-.287.236-.814.276-1.233.074-.769-.086-2.992-.204-3.306-.318-.016-.635-.018-1.08-.066-.196-.172-.217-.29-.343-.435-.15-.083-.595-.053-.567-.252.695-.123 1.695-.028 2.345-.203.124-.196-.013-.452.014-.665-.003-.195.057-.39.252-.385.192.006.285.222.292.393.014.168-.009.495.142.622.757.241 1.573.063 2.228.207.057.215-.39.14-.513.325-.065.096-.157.264-.247.336-.35.053-.703-.023-1.047.11-.104.9-.016 2.255.032 3.3.056.439.108.756.228 1.229.79.158 2.01.128 2.777.247.722.422 1.54.918 1.903 1.456 0 0 .043 1.77.224 2.464.282.205.908.346 1.344.56.886.433 1.945.86 2.576 1.455.728 1.244 1.145 3.32 1.456 5.04.087.48.055.9.21 1.344.272.156.759.07 1.022.336 1.185 1.196 1.574 3.559 1.792 4.816.004.236.029.362.084.672 2.85.159 10.723 1.325 12.571 1.792 1.558.625 3.895 2.57 5.936 3.695 2.251 1.242 5.427 2.667 6.943 3.36 1.003.042 2.713-.138 3.36 0 1.313.3 4.029 2.848 4.816 3.36.846.398 2.43.554 2.912.84.546.612 1.184 1.926 1.567 2.632 2.928 1.497 7.924 3.211 9.968 4.48a41.587 41.587 0 012.24 3.247c1.75.707 7.228 6.623 9.155 7.96 2.37.794 4.803 3.59 6.524 5.48 2.078 1.43 4.774 3.635 6.047 5.151 1.785 1.647 3.916 2.085 5.208 4.2 1.473 2.41 3.407 5.086 4.368 7.671"
                            id="path380" fill="none" stroke="#000" strokeWidth=".265" strokeLinecap="butt" strokeLinejoin="miter" strokeOpacity="1" />
                        </g>
                      </g>
                    )}
                  </Spring>
                </g>

                <Spring
                  from={{ x: 1000 }}
                  to={{ x: 300 }}
                  config={{ duration: durationLake, delay: delayLake }}
                >
                  {props => (
                    <g
                      transform="translate(0.35627755,-24.065739)"
                      id="layer5"
                      style={{
                        //Lake
                        fill: '#ffffff',
                        fillOpacity: '1',
                        strokeDasharray: '1000',
                        strokeDashoffset: props.x,
                      }}
                    >
                      <path
                        id="path889"
                        d="m 97.679196,120.05205 c -2.261838,6.1757 -10.760803,13.58312 -13.460502,18.81391 -2.598827,5.03534 0.632446,13.75353 5.17212,17.14469 17.536136,13.09957 50.704076,6.2809 64.610156,1.42569 20.05938,-6.26836 35.56509,-10.71554 56.11824,-22.25185 16.99116,-12.32463 17.81242,-16.33452 9.74398,-24.18541 C 209.61732,102.29383 194.96087,96.079201 181.65256,93.844797 165.97123,90.35913 150.97012,88.106274 135.51146,86.005291 92.373132,80.100716 55.570635,77.41223 17.484099,82.463309 -7.3222673,85.701231 -23.253688,102.05128 -0.22398589,111.53968 48.99424,121.75949 97.709966,116.52101 97.679196,120.05205 Z"
                        style={{
                          fill: 'none',
                          fillOpacity: '1',
                          stroke: '#000000',
                          strokeWidth: '0.15875px',
                          strokeLinecap: 'butt',
                          strokeLinejoin: 'miter',
                          strokeOpacity: '1'
                        }}
                      />
                    </g>
                  )}
                </Spring>


                <g
                  transform="translate(0.35627755,-24.065739)"
                  id="layer4"
                >
                  <Spring
                    from={{ x: 1000 }}
                    to={{ x: 450 }}
                    config={{ duration: durationPamp }}
                  >
                    {props => (
                      <g
                        transform="matrix(0.45932101,0,0,0.45932101,5.7908021,84.559174)"
                        id="g863"
                        style={{
                          fill: '#ffffff',
                          fillOpacity: '1',
                          strokeDasharray: '1000',
                          strokeDashoffset: props.x
                          //strokeDashoffset: this.state.dashOffsetPamp,
                        }}
                      >
                        <g id="g277">
                          <path
                            id="path273"
                            d="m 186.29556,83.250356 v 0 c 0,0 -3.55512,-12.639206 -5.22181,-17.508402 -3.22523,-6.450464 -5.06821,-7.525543 -8.7542,-8.139872 -3.22523,-0.153584 -5.85828,2.19588 -8.60062,6.450465 -3.99314,-8.907785 -8.67665,-29.122814 -22.26946,-33.020235 -1.51429,-0.162399 -3.01132,0.052 -4.47676,0.562768 -11.9415,4.162106 -21.78584,28.003574 -21.78584,28.003574 0,0 -4.91464,-9.522113 -14.59034,-9.522113 -2.349563,-0.141907 -4.5588,0.207313 -6.635488,1.141988 -2.903665,1.30688 -5.548195,3.758335 -7.954849,7.612212 -0.857473,-1.620643 -2.06389,-3.336259 -3.575293,-4.951533 -2.966154,-3.170002 -7.10695,-5.953541 -12.09012,-6.874318 -1.883828,-0.313971 -4.177731,-0.503137 -6.711615,-0.142261 -0.908883,0.129444 -1.848642,0.329656 -2.811427,0.62026 -4.230967,1.277063 -8.906607,4.299762 -13.360749,10.733524 C 44.231757,63.745381 32.71307,84.325435 32.71307,84.325435 L 23.240233,80.055449 33.991556,61.702181 c 1.30319,-2.171985 5.212762,-5.321362 5.212762,-5.321362 4.561167,-2.497782 21.61342,-8.89793 21.61342,-8.89793"
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                          />
                          <path
                            id="path275"
                            d="m 35.946341,84.508016 c 0,0 11.557679,-23.924081 13.683502,-27.258404 3.692373,-6.298754 14.552296,-9.88253 18.13607,-9.448132 11.294318,2.280583 15.050385,7.99249 16.72428,12.054513 l 2.823579,0.217198 c 2.171984,-4.561167 6.784847,-9.308374 13.466298,-8.905135 7.13538,0.853338 11.71808,5.056829 13.9007,10.642724 l 2.49779,0.217197 c 6.77923,-19.276519 16.67054,-30.038983 23.89182,-29.430387 12.90889,1.087939 18.89544,28.377505 20.85105,32.796963 l 2.38919,0.217199 c 2.46559,-4.776729 4.32865,-6.69051 8.36214,-6.950349 3.14701,-0.202732 6.17747,4.249648 7.71054,8.144941 1.7966,5.166395 4.66977,16.072683 4.66977,16.072683"
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                          />
                          <path
                            d="M 63.747742,46.606888 67.440116,45.0865 98.60809,44.217706 100.99727,42.37152 137.16081,31.2944"
                            id="path281"
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                          />
                          <path
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                            d="m 98.608088,44.217706 -5.429959,7.058948"
                            id="path283"
                          />
                          <path
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                            d="M 93.178129,51.276654 82.644005,53.883035"
                            id="path285"
                          />
                          <path
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                            d="M 6.4073539,78.209261 7.2761477,19.348485 18.570466,21.737668 11.511517,78.535058 Z"
                            id="path287"
                          />
                          <path
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                            d="m 10.534124,61.050585 -0.108599,1.846185 20.959649,2.714983 1.520388,-2.389183 z"
                            id="path289"
                          />
                          <path
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                            d="m 32.71307,84.325435 3.233271,0.182581 v 0"
                            id="path291"
                          />
                          <path
                            style={{
                              fill: '#ffffff',
                              stroke: '#000000',
                              strokeWidth: '0.26458332px',
                              strokeLinecap: 'butt',
                              strokeLinejoin: 'miter',
                              strokeOpacity: '1'
                            }}
                            d="m 186.29556,83.250354 -1.24249,-0.371327 v 0"
                            id="path293"
                          />
                        </g>
                      </g>
                    )}
                  </Spring>
                </g>
              </svg>
            </div>
          </WrapperLogo>

          {
            // Nome e menu
          }

          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: durationTitle, delay: delayTitle }}
          >
            {props => (
              <div style={{ paddingTop: "20px" }}>
                <div
                  style={{ cursor: "pointer", display: "inline-block" }}
                  onClick={() => {
                    this.handleClickOutside()
                    this.changePage("Home")
                  }}
                >
                  <Claric ops={props.opacity} />
                </div>
                {innerWidth < 800 ? (
                  <div style={{ display: "inline-block" }}>
                    <div onClick={() => this.toggleList()}>
                      <i
                        className="fa fa-bars"
                        style={{
                          color: "#999999",
                          padding: "10px",
                          fontSize: "32px",
                          cursor: "pointer",
                          opacity: props.opacity,
                          verticalAlign: "super",
                        }}
                      />
                    </div>
                    {this.state.listOpen && (
                      <div id="divDrop" style={divDropDown}>
                        {menu.map((e, i) => (
                          <div
                            key={i}
                            style={{
                              color: "#999999",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.toggleList()
                              this.changePage(e)
                            }}
                          >
                            {e === "Home" ? (
                              <i
                                className="fa fa-home"
                                style={iconStyle2}
                                onClick={() => this.changePage("Home")}
                              />
                            ) : (
                              e
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <i
                    className="fa fa-home"
                    style={{
                      color: "#999999",
                      padding: "10px",
                      fontSize: "32px",
                      cursor: "pointer",
                      opacity: props.opacity,
                      verticalAlign: "super",
                    }}
                    onClick={() => this.changePage("Home")}
                  />
                )}
                <p
                  style={{
                    color: "#999999",
                    opacity: props.opacity,
                    fontSize: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  23/10/2021
                </p>
                <p  style={{
                    color: "#999999",
                    opacity: props.opacity,
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    maxWidth: '800px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    margin: 'auto',
                    letterSpacing: '1px',
                    lineHeight: '16px',
                    paddingTop: '10px'
                  }}>
                  Devido a situação perto das datas onde planejávamos celebrar o
                  casamento (23/05/2020, e depois 14/11/2020), decidimos adiar a celebração do nosso
                  casamento mais uma vez para 23/10/2021. Conseguir celebrar com nossos
                  queridos convidados com segurança e saúde é nossa prioridade.
                  Esperamos vocês!
                  <i className="fa fa-heart" />
                </p>
                <p style={{
                    color: "#999999",
                    opacity: props.opacity,
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    maxWidth: '800px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    margin: 'auto',
                    letterSpacing: '1px',
                    lineHeight: '16px',
                    paddingTop: '10px'
                  }}>CONFIRMADO!!!</p>
              </div>
            )}
          </Spring>

          {innerWidth >= 800 && (
            <Spring
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
              config={{ duration: durationMenu, delay: delayMenu }}
            >
              {props => (
                <WrapperMenu style={{ opacity: props.opacity }}>
                  {menu.map((e, i) =>
                    e !== "Home" ? (
                      <WrapperMenuItem
                        key={i}
                        onClick={() => this.changePage(e)}
                      >
                        {e}
                      </WrapperMenuItem>
                    ) : null
                  )}
                </WrapperMenu>
              )}
            </Spring>
          )}

          {
            // Páginas
          }

          {this.state.page === "Salões de Beleza" && (
            <SalaoDeBeleza desize={desize} />
          )}

          {this.state.page === "Hoteis" && <Hoteis desize={desize} />}

          {this.state.page === "Lista de Presentes" && (
            <Presentes desize={desize} />
          )}

          {this.state.page === "Local da festa" && (
            <LocalDaFesta desize={desize} />
          )}

          {this.state.page === "RSVP" && <RSVP desize={desize} />}

          {this.state.page === "Dicas" && <Dicas desize={desize} />}
        </div>

        <div
          style={{
            height: "100vh",
            minHeight: "450px",
            maxWidth: "100%",
            overflowX: "scroll",
          }}
          onClick={() => this.handleClickOutside()}
        >
          {true && (
            <Pics
              durationPhotos={durationPhotos}
              delayPhotos={delayPhotos}
              picturesScale={picturesScale}
              minpicScale={minpicScale}
            />
          )}

          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{
              duration: durationPhotos / 6,
              delay: delayPhotos + durationPhotos / 2,
            }}
          >
            {props => (
              <Tutuca
                picturesScale={picturesScale}
                minpicScale={minpicScale}
                opacity={props.opacity}
              />
            )}
          </Spring>

          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: durationPhotos, delay: delayPhotos }}
          >
            {props => (
              <div
                className="parallaxLayer"
                style={{
                  width: "" + tacoWidth + "px",
                  position: "absolute",
                  height: "350px",
                  minWidth: "100%",
                  marginLeft: marginLeftTaco,
                  backgroundImage: 'url("chão de tacob3__2b.svg")',
                  transform: "scale(" + picturesScale + ")",
                  bottom: bottomTaco,
                  opacity: props.opacity,
                }}
              ></div>
            )}
          </Spring>
        </div>
      </div>
    )
  }
}

export default App
