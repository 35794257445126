import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'


const Wrapper = styled.div`
padding: 15px;
padding-top: 50px;
padding-bottom: 50px;
opacity: ${props => props.opacity};
`

const HtwoTitle = styled.h2`
color: #999999;
padding: 30px;
`

const ParText = styled.p`
color: #999999;
`

const RSVP = (props) => {
    const desize = props.desize
    return (
        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
        >
            {props => (
                <Wrapper opacity={props.opacity}>
                    <HtwoTitle>RSVP</HtwoTitle>
                    <section style={{ top: '' + (100 * desize) + 'px' }}>
                        <ParText>Confirme a sua presença através do email: <span style={{ color: 'blue' }}>casamento@clariceejoao.com</span></ParText>
                        <ParText>Ou através do Whatsapp com a nossa cerimonialista Vivi Barbosa <span style={{ color: 'blue' }}>(+31) 99809-2814</span></ParText>
                    </section>
                </Wrapper>
            )}
        </Spring>)
}

export default RSVP


