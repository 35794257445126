import React, { useState, useEffect } from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
bottom: 0px;
position: absolute !important;
opacity: ${props => props.opacity};
`

const Tutuca = (props) => {
    const [counter, setCounter] = useState(false)

    const opacity = props.opacity

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter( counter => !counter)
        }, 250)
        return () => clearInterval(interval);
    }, [])

   

    return (
        <Wrapper id="tutuca" opacity={opacity}>

            <div className='parallaxLayer' style={{
                left: '' + 3200 * props.picturesScale + 'px',
                width: '310px',
                position: 'absolute',
                bottom: '' + -30 - (props.minpicScale - props.picturesScale) * 250 + 'px',
                height: '405px',
                zIndex: '10',
                backgroundImage: 'url("tutucag_1.svg")',
                transform: 'scale(' + props.picturesScale * 0.62 / 0.54 + ')'
            }}>
            </div>

            {counter ?
                <div className='parallaxLayer' style={{
                    left: '' + 3200 * props.picturesScale + 'px',
                    width: '310px',
                    position: 'absolute',
                    bottom: '' + -30 - (props.minpicScale - props.picturesScale) * 250 + 'px',
                    height: '405px',
                    zIndex: '10',
                    backgroundImage: 'url("tutucag_2.svg")',
                    transform: 'scale(' + props.picturesScale * 0.62 / 0.54 + ')'
                }}>
                </div>
                :
                <div className='parallaxLayer' style={{
                    left: '' + 3200 * props.picturesScale + 'px',
                    width: '310px',
                    position: 'absolute',
                    bottom: '' + -30 - (props.minpicScale - props.picturesScale) * 250 + 'px',
                    height: '405px',
                    zIndex: '10',
                    backgroundImage: 'url("tutucag_3.svg")',
                    transform: 'scale(' + props.picturesScale * 0.62 / 0.54 + ')'
                }}>
                </div>
            }

        </Wrapper>
    )
}

export default Tutuca