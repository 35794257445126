import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'


const Wrapper = styled.div`
padding: 15px;
padding-top: 50px;
padding-bottom: 50px;
opacity: ${props => props.opacity};
`

const HtwoTitle = styled.h2`
color: #999999;
padding: 30px;
`

const ParText = styled.p`
color: #999999;
`

const SalaoDeBeleza = (props) => {
    return (
        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
        >
            {props => (
                <Wrapper opacity={props.opacity}>
                    <HtwoTitle>Salões de Beleza</HtwoTitle>
                    <ParText>
                        Salão tifs <a rel="noopener noreferrer" target='_blank' href='http://www.tifs.com.br'>clique aqui</a>
                    </ParText>
                    <ParText>
                        Salão clip <a rel="noopener noreferrer" target='_blank' href='http://www.salaoclip.com.br'>clique aqui</a>
                    </ParText>
                    {/* <ParText>
                        Celio Faria <a rel="noopener noreferrer" target='_blank' href='http://ww.celiofaria.com.br'>clique aqui</a>
                    </ParText>
                    <ParText>
                        Yolo <a rel="noopener noreferrer" target='_blank' href='http://yolobh.com.br'>clique aqui</a>
                    </ParText> */}
                </Wrapper>
            )}
        </Spring>
    )
}

export default SalaoDeBeleza