import React from 'react'
import { Spring } from 'react-spring/renderprops'

const Pics = (props) => {
  const picturesScale = props.picturesScale, minpicScale = props.minpicScale
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ duration: props.durationPhotos, delay: props.delayPhotos }}
    >
      {props => (

        <div style={{
          opacity: props.opacity, height: '250px', width: '250px', marginTop: '-20px', transform: 'scale(' + (picturesScale * 0.6 / minpicScale) + ')',
          marginLeft: props.marginLeft
        }} >


          {//2016
          }

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '0px', left: '0px',
            width: '442px', height: '339px', transform: 'scale(0.8)', backgroundImage: 'url("16/photo1.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '50px', left: '50px',
            width: '358px', height: '1083px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo3.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '276px', left: '345px',
            width: '445px', height: '341px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo2.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '10px', left: '365px',
            width: '440px', height: '341px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo4.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '10px', left: '365px',
            width: '440px', height: '341px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo4.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '10px', left: '605px',
            width: '440px', height: '341px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo5.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '276px', left: '655px',
            width: '440px', height: '341px', transform: 'scale(0.4)', backgroundImage: 'url("16/photo6.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '545px', left: '425px',
            width: '440px', height: '341px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo7.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '25px', left: '970px',
            width: '440px', height: '341px', transform: 'scale(0.85)', backgroundImage: 'url("16/photo8.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '400px', left: '1022px',
            width: '398px', height: '270px', transform: 'scale(.8)  rotate(90deg)', backgroundImage: 'url("16/photo9.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '630px', left: '760px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo10.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '630px', left: '1010px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo11.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '1410px',
            width: '442px', height: '340px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo12.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '305px', left: '1410px',
            width: '442px', height: '340px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo13.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '585px', left: '1410px',
            width: '442px', height: '340px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo14.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '1710px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo15.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '210px', left: '1935px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo16.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '1935px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo17.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '210px', left: '1710px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo18.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '210px', left: '1710px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo24.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '390px', left: '1935px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo20.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '390px', left: '1710px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo19.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '570px', left: '1710px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo21.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '570px', left: '1935px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo22.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '2230px',
            width: '442px', height: '340px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo23.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '305px', left: '2465px',
            width: '442px', height: '340px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo25.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '585px', left: '2690px',
            width: '442px', height: '340px', transform: 'scale(0.75)', backgroundImage: 'url("16/photo26.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '430px', left: '2170px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo27.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '255px', left: '2170px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo28.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '610px', left: '2170px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo29.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '530px', left: '2400px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo30.svg")'
          }} />



          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '2520px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo31.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '2755px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo32.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '255px', left: '2755px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo33.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '2985px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo34.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '210px', left: '2985px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo35.svg")'
          }} />




          <div className='parallaxLayer' style={{
            position: 'absolute', top: '390px', left: '2985px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo36.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '560px', left: '2985px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo37.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '270px', left: '3265px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo38.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '3265px',
            width: '442px', height: '340px', transform: 'scale(0.65) rotate(180deg)', backgroundImage: 'url("16/photo39.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '3600px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo40.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '740px', left: '2985px',
            width: '442px', height: '340px', transform: 'scale(0.45)  rotate(180deg)', backgroundImage: 'url("16/photo41.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '270px', left: '3600px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo42.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '210px', left: '3880px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo43.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '510px', left: '3265px',
            width: '442px', height: '340px', transform: 'scale(0.65) rotate(180deg)', backgroundImage: 'url("16/photo44.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '3880px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo45.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '510px', left: '3600px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo46.svg")'
          }} />






          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '4120px',
            width: '442px', height: '340px', transform: 'scale(0.55) rotate(180deg)', backgroundImage: 'url("16/photo47.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '230px', left: '4120px',
            width: '442px', height: '340px', transform: 'scale(0.55)', backgroundImage: 'url("16/photo48.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '390px', left: '3880px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo49.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '430px', left: '4120px',
            width: '442px', height: '340px', transform: 'scale(0.55)', backgroundImage: 'url("16/photo50.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '560px', left: '3880px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo51.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '20px', left: '4370px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo52.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '20px', left: '4605px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo53.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '200px', left: '4370px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo54.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '200px', left: '4605px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo55.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '380px', left: '4370px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo56.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '380px', left: '4605px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo58.svg")'
          }} />






          <div className='parallaxLayer' style={{
            position: 'absolute', top: '560px', left: '4370px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo60.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '560px', left: '4605px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo59.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '740px', left: '4370px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo61.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '740px', left: '4605px',
            width: '442px', height: '340px', transform: 'scale(0.45)  rotate(180deg)', backgroundImage: 'url("16/photo62.svg")'
          }} />




          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '4875px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo63.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '270px', left: '4875px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo64.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '510px', left: '4875px',
            width: '442px', height: '340px', transform: 'scale(0.65) rotate(180deg)', backgroundImage: 'url("16/photo65.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '750px', left: '4875px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo66.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '20px', left: '5145px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo67.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '200px', left: '5145px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo68.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '380px', left: '5145px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo69.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '560px', left: '5145px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo70.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '740px', left: '5145px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo71.svg")'
          }} />




          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '5420px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo72.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '270px', left: '5420px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo73.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '510px', left: '5420px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo74.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '750px', left: '5420px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo75.svg")'
          }} />




          <div className='parallaxLayer' style={{
            position: 'absolute', top: '20px', left: '5690px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo81.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '200px', left: '5690px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo77.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '380px', left: '5690px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo78.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '560px', left: '5690px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo79.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '740px', left: '5690px',
            width: '442px', height: '340px', transform: 'scale(0.45)', backgroundImage: 'url("16/photo86.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '5970px',
            width: '442px', height: '340px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo76.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '330px', left: '5970px',
            width: '442px', height: '340px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo82.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '630px', left: '5970px',
            width: '442px', height: '340px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo83.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '6300px',
            width: '442px', height: '340px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo84.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '330px', left: '6300px',
            width: '442px', height: '340px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo85.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '630px', left: '6300px',
            width: '442px', height: '340px', transform: 'scale(0.7)', backgroundImage: 'url("16/photo80.svg")'
          }} />





          <div className='parallaxLayer' style={{
            position: 'absolute', top: '30px', left: '6660px',
            width: '442px', height: '340px', transform: 'scale(0.8)', backgroundImage: 'url("16/photo87.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '330px', left: '6660px',
            width: '442px', height: '340px', transform: 'scale(0.8)', backgroundImage: 'url("16/photo88.svg")'
          }} />

                  {/* <div className='parallaxLayer' style={{
                    position: 'absolute', top: '395px', left: '7530px',
                    width: '442px', height: '340px', transform: 'scale(3)', backgroundImage: 'url("16/photo87b.svg")'
                  }} /> */}


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '-155px', left: '7015px',
            width: '354px', height: '1083px', transform: 'scale(0.6)', backgroundImage: 'url("16/photo90.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '655px', left: '6975px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo91.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '655px', left: '7225px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo92.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '655px', left: '7475px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo93.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '655px', left: '7725px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo94.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '655px', left: '7975px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo95.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '655px', left: '8225px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo96.svg")'
          }} />


          <div className='parallaxLayer' style={{
            position: 'absolute', top: '50px', left: '7325px',
            width: '442px', height: '340px', backgroundImage: 'url("16/photo97.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '390px', left: '7325px',
            width: '442px', height: '340px', backgroundImage: 'url("16/photo98.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '415px', left: '7700px',
            width: '442px', height: '340px', transform: 'scale(0.65)', backgroundImage: 'url("16/photo99.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '180px', left: '7700px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo100.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '-20px', left: '8100px',
            width: '442px', height: '340px', transform: 'scale(0.8)', backgroundImage: 'url("16/photo101.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '-35px', left: '7700px',
            width: '442px', height: '340px', transform: 'scale(0.5)', backgroundImage: 'url("16/photo102.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '305px', left: '8100px',
            width: '442px', height: '424px', backgroundImage: 'url("16/photo103.svg")'
          }} />

          <div className='parallaxLayer' style={{
            position: 'absolute', top: '-20px', left: '8550px',
            width: '442px', height: '340px', transform: 'scale(0.8)', backgroundImage: 'url("16/photo104.svg")'
          }} />


        </div>
      )}
    </Spring>

  )
}

export default Pics