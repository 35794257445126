import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'
import Map from './Imagem1.jpg'

const Wrapper = styled.div`
padding: 15px;
padding-top: 50px;
padding-bottom: 20px;
opacity: ${props => props.opacity};
max-width: 800px;
margin: auto;
`

const HtwoTitle = styled.h2`
color: #999999;
padding: 30px;
`

const ParText = styled.p`
color: #999999;
`

const LocalDaFesta = (props) => {
    const desize = props.desize
    return (
        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
        >
            {props => (
                <Wrapper opacity={ props.opacity }>
                    <HtwoTitle>Dicas</HtwoTitle>
                    <section style={{ top: '' + (100 * desize) + 'px' }}>

                        <ParText>Queridos amigas, amigos e familiares, </ParText>

                        <ParText>Nosso casamento será realizado no espaço <b>Colonial Pampulha</b>, uma bela casa que fica às margens da Lagoa da Pampulha.</ParText>

                        <ParText>Para aqueles que quiserem se hospedar próximo ao local da cerimônia/recepção, a estadia na região da Pampulha é uma boa escolha!</ParText>

                        <ParText>Essa região fica um pouco mais afastada do centro da cidade, mas nela destaca-se um conjunto arquitetônico projetado por Niemeyer, situado ao redor da Lagoa da Pampulha e que conta com alguns pontos turísticos importantes de Belo Horizonte. São eles: a Igrejinha da Pampulha, a Casa do Baile, o Museu de Arte e o Iate Tênis Clube.</ParText>

                        <ParText>Para os que preferirem aproveitar mais a cidade, sentir um pouco mais o movimento central de Belo Horizonte e conhecer pontos turísticos como a Praça do Papa, o Mercado Central, o Mercado Novo, o simpático bairro da Savassi, e ainda alguns centros culturais e museus ao redor da Praça da Liberdade, a melhor escolha é se hospedar na região da Savassi. Ela está localizada a 14km do local da cerimônia/recepção, e é de fácil acesso através de táxi (em torno de R$35,00 a viagem) e aplicativos (percurso entre R$23 e R$27, 00).</ParText>

                        <ParText>Os salões de beleza indicados ficam próximos a região da Savassi. Eles têm o melhor custo/benefício.</ParText>

                        <ParText>Espero que as dicas ajudem na escolha.</ParText>

                        <ParText>Um abraço e beijo grandes. Esperamos vocês com alegria para celebrar conosco esse momento feliz de nossas vidas.</ParText>

                        <ParText><a rel="noopener noreferrer" target='_blank' href="https://pt.wikipedia.org/wiki/Conjunto_Arquitet%C3%B4nico_da_Pampulha">https://pt.wikipedia.org/wiki/Conjunto_Arquitet%C3%B4nico_da_Pampulha</a></ParText>

                        <ParText><a rel="noopener noreferrer" target='_blank' href="http://memorialvale.com.br/">http://memorialvale.com.br/</a></ParText>

                        <ParText><a rel="noopener noreferrer" target='_blank' href="http://www.mmgerdau.org.br/">http://www.mmgerdau.org.br/</a></ParText>

                        <ParText><a rel="noopener noreferrer" target='_blank' href="http://mercadocentral.com.br/">http://mercadocentral.com.br/</a></ParText>
                        
                        <ParText><a rel="noopener noreferrer" target='_blank' href="http://culturabancodobrasil.com.br/portal/belo-horizonte/">http://culturabancodobrasil.com.br/portal/belo-horizonte/</a></ParText>

                        <img alt="Mapa de Beagá" style={{paddingTop: '20px', width: '600px' , maxWidth: '90vw'}} src={Map} />

                    </section>
                </Wrapper>
            )}
        </Spring>
    )
}

export default LocalDaFesta


